import React from 'react';
import { Layout, Image } from '../components';

const AboutPage = () => {
  return (
    <Layout
      title="About Dewebgineer"
      description="Learn more about Dewebgineer Solutions, a Barbadian web development agency. "
    >
      <section className="page-bg py-16 h-full w-full flex-1 border-t border-gray-300">
        <div className="max-w-screen-lg mx-auto px-4 md:px-6">
          <div className="mx-auto text-center font-bold text-black mb-8">
            <h1 className="text-4xl mb-3">About Dewebgineer</h1>
            <p>
              We are a Software Development agency based in the beautiful island
              of Barbados.
            </p>
          </div>
          <div className="bg-white shadow-md px-6 py-12 rounded-lg flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 mb-6 md:mb-0 md:px-6">
              {/* <picture>
                <source
                  srcSet={`${require('../assets/images/about/diondre-edwards-300.webp')} 400w, ${require('../assets/images/about/diondre-edwards-500.webp')} 600w, ${require('../assets/images/about/diondre-edwards-500.webp')} 1000w`}
                  type="image/webp"
                />
                <source
                  srcSet={
                    require('../assets/images/about/diondre-edwards.jpg?resize&sizes[]=300&sizes[]=600&sizes[]=1000')
                      .srcSet
                  }
                  type="image/png"
                />
                <img
                  src={
                    require('../assets/images/about/diondre-edwards.jpg?resize&sizes[]=300&sizes[]=600&sizes[]=1000')
                      .srcSet
                  }
                  className="h-auto w-full md:max-w-full rounded-lg"
                  alt="what people say about dewebgineer"
                />
              </picture> */}
              <Image
                src="about/diondre-edwards.jpg"
                className="h-auto w-full md:max-w-full rounded-lg"
                alt="what people say about dewebgineer"
              />
            </div>
            <div className="w-full md:w-3/4 space-y-4 md:px-6">
              <p>
                Hi, My name is Diondre Edwards and I&apos;m the founder of
                Dewebgineer. I have been building web applications
                professionally for the last 4 years.
              </p>
              <p>
                During my career I have also done freelance development part
                time for clients and always enjoyed the experience of solving
                their problems.
              </p>
              <p>
                These experiences created the motivation I needed to start
                Dewebgineer to allow me to solve client problems on a larger
                scale.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;
